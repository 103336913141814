var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("stepNav", { attrs: { name: "Summering" } }),
        _c("p", { staticClass: "non-printable" }, [
          _vm._v(_vm._s(_vm.disclaimerText)),
        ]),
        _c(
          "a",
          {
            staticClass: "non-printable",
            attrs: {
              href: "https://www.benders.se/bender-safe/",
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_vm.$t("summary.more_info_SAFE")))]
        ),
        _vm.rowsSnowprotection > 1
          ? _c("div", { staticClass: "is-warning" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("alternatives.snow_zone_warning")) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "non-printable form-group" },
          [
            _c("radioButtonGroup", {
              attrs: {
                label: _vm.$t("summary.roof_safety_system"),
                options: _vm.safetySystems,
              },
              model: {
                value: _vm.safetySystem,
                callback: function ($$v) {
                  _vm.safetySystem = $$v
                },
                expression: "safetySystem",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCardModalActive,
                expression: "isCardModalActive",
              },
            ],
            staticClass: "choices printable",
          },
          [
            _c(
              "h2",
              { staticClass: "title is-size-4 is-uppercase only-printable" },
              [_vm._v(_vm._s(_vm.$t("summary.your_choices")))]
            ),
            _c(
              "div",
              {
                ref: "choicesTable",
                staticClass: "columns is-centered printable",
                attrs: { id: "choicesTable" },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "table is-narrow is-fullwidth-mobile dd-table",
                  },
                  [
                    _vm._l(_vm.cSelections, function (selection) {
                      return [
                        _vm.formatValue(selection.value)
                          ? _c("tr", { key: selection.name }, [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "has-text-weight-bold has-text-right",
                                },
                                [_vm._v(_vm._s(selection.name))]
                              ),
                              _c("td", { staticClass: "has-text-left" }, [
                                _vm._v(
                                  _vm._s(_vm.formatValue(selection.value))
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    }),
                    _vm.selectedSnowProtection
                      ? [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "has-text-weight-bold has-text-right",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("summary.snow_protection_rows"))
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "has-text-left" }, [
                              _vm._v(_vm._s(_vm.rowsSnowprotection)),
                            ]),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "has-text-weight-bold has-text-right",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("summary.snow_protection_calculted")
                                  )
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "has-text-left" }, [
                              _vm._v(_vm._s(_vm.distanceConsole)),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
        _c("div", {
          ref: "choicesTablePrint",
          staticClass: "only-printable",
          attrs: { id: "choicesTablePrint" },
        }),
        _c(
          "b-modal",
          {
            attrs: { active: _vm.emailSummarySent, width: 640, scroll: "keep" },
            on: {
              "update:active": function ($event) {
                _vm.emailSummarySent = $event
              },
            },
          },
          [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c("p", { staticClass: "is-size-6 is-size-5-tablet" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("summary.email_sent")) +
                      ": "
                  ),
                  _c("b", { staticClass: "has-text-weight-bold" }, [
                    _vm._v(_vm._s(_vm.emailSummaryReceiver)),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "table",
      {
        ref: "summaryTable",
        staticClass: "table margin-xxxlarge-vertical is-fullwidth is-size-7",
        attrs: { id: "summaryTable" },
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "is-uppercase has-text-left" }, [
              _vm._v(_vm._s(_vm.$t("generic.product"))),
            ]),
            _c(
              "th",
              { staticClass: "is-uppercase has-text-left is-hidden-mobile" },
              [_vm._v(_vm._s(_vm.$t("generic.color")))]
            ),
            _c("th", { staticClass: "is-uppercase has-text-right" }, [
              _vm._v(_vm._s(_vm.$t("generic.quantity"))),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.sortedTableRows, function (tableRow) {
            return _c("tr", { key: tableRow.artNbr }, [
              _c("td", { staticClass: "has-text-left is-size-6" }, [
                _vm._v("\n          " + _vm._s(tableRow.product) + " "),
                _c(
                  "span",
                  {
                    staticClass:
                      "is-block has-text-weight-normal has-text-light is-size-8",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("generic.artnr")) +
                        " " +
                        _vm._s(tableRow.artNbr)
                    ),
                  ]
                ),
              ]),
              _c("td", { staticClass: "has-text-left is-hidden-mobile" }, [
                _vm._v(_vm._s(tableRow.color)),
              ]),
              _c("td", { staticClass: "has-text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(tableRow.qty) +
                    " " +
                    _vm._s(
                      tableRow.qty != "-"
                        ? _vm.articleIdsLoaded
                          ? _vm.articleIds[tableRow.artNbr].unit
                          : tableRow.unit
                        : ""
                    ) +
                    "\n        "
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "only-printable disclaimer has-text-weight-normal has-text-light is-size-8",
        attrs: { id: "disclaimer" },
      },
      [_vm._v("\n    " + _vm._s(_vm.disclaimerText) + "\n  ")]
    ),
    _c("div", { staticClass: "non-printable margin-medium-bottom" }, [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              _vm.isCardModalActive = !_vm.isCardModalActive
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.isCardModalActive
                  ? _vm.$t("summary.hide_my_choices")
                  : _vm.$t("summary.show_my_choices")
              ) +
              "\n        "
          ),
          _c(
            "svg",
            {
              class: ["icon-triangle", !_vm.isCardModalActive ? "flip" : false],
              attrs: {
                width: "12",
                height: "12",
                viewBox: "0 0 16 16",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
              },
            },
            [
              _c("polygon", {
                attrs: { points: "8,5 13,10 3,10", fill: "currentColor" },
              }),
            ]
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "non-printable" }, [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c(
                "b-button",
                {
                  staticClass: "is-uppercase has-text-weight-bold",
                  attrs: { size: "is-small", type: "is-link" },
                  on: {
                    click: function ($event) {
                      return _vm.goToStep(0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("summary.restart")))]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { staticClass: "level-item" }, [
            _c(
              "form",
              {
                ref: "pdfSummaryForm",
                attrs: {
                  method: "POST",
                  target: "_blank",
                  action: _vm.pdfSummaryAction,
                },
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "name" },
                  domProps: { value: _vm.pdfSummaryName },
                }),
                _c("input", {
                  attrs: { type: "hidden", name: "body" },
                  domProps: { value: _vm.pdfSummaryBody },
                }),
                _c("input", {
                  attrs: { type: "hidden", name: "print" },
                  domProps: { value: _vm.pdfSummaryPrint ? "y" : "n" },
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "is-uppercase has-text-weight-bold",
                    attrs: { size: "is-small", type: "is-link" },
                    on: { click: _vm.pdfSummarySubmit },
                  },
                  [_vm._v(_vm._s(_vm.$t("summary.save_pdf")))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "level-item" },
            [
              _c(
                "b-button",
                {
                  staticClass: "is-uppercase has-text-weight-bold",
                  attrs: { size: "is-small", type: "is-link" },
                  on: { click: _vm.printSummary },
                },
                [_vm._v(_vm._s(_vm.$t("summary.print")))]
              ),
            ],
            1
          ),
          _vm.showAddToCartBtn
            ? _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "button-loader",
                          rawName: "v-button-loader:dot-collision",
                          value:
                            !_vm.articleIdsLoaded ||
                            _vm.$store.state.frameData.loading,
                          expression:
                            "!articleIdsLoaded || $store.state.frameData.loading",
                          arg: "dot-collision",
                        },
                      ],
                      staticClass: "is-uppercase has-text-weight-bold",
                      attrs: {
                        size: "is-small",
                        type: "is-link",
                        disabled:
                          !_vm.articleIdsLoaded ||
                          _vm.$store.state.frameData.loading,
                      },
                      on: { click: _vm.addToCart },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("summary.add_to_cart")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }