export default {
  computed:{    
    roofRailConsole () {
      let product = this.products.find(p => p.type === 'roofRailConsole')
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;

      if (this.safetySystem == 'PW') {
        fullProduct = this.getPWConsoleProduct();
      }

      // Calculate amount
      let roofRailLength = this.roofRail['length']/1000
      let roofRailLengths = this.selections.roofRailLengths.value
      let multiplier = this.roofMultiplier / 1000
      
      let amount = this.amountOfAccessories(roofRailLengths, roofRailLength, 0, multiplier)
            
      // Add properties
      fullProduct.amount = amount
      // fullProduct.pallets =  1,
      // fullProduct.totalWeight =  2,
      // fullProduct.values =  1,
      fullProduct.unit = 'st';

      if (this.safetySystem == 'SAFE') {
        let bigpack = this.bigpack(fullProduct, amount);
        fullProduct.singlepack = bigpack[0];
        fullProduct.bigpack = bigpack[1];
        fullProduct.sort = 9
      }else {
        fullProduct.singlepack = amount;
      }

      return fullProduct
    }
  },
  methods: {}
}
